<template>
  <v-app class="background">
    <Toolbar />
    <v-container>
      <v-layout row wrap justify-center align-content-center pt-16 pb-16>
        <v-flex xs5 lg4 pa-1>
          <v-card>
            <v-img
              @click="fn_showDeatailVanilla()"
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              max-height="360px"
              src="@/assets/img/Menu/Vanilla.jpg"
            >
              <v-app-bar flat color="rgba(0, 0, 0, 0)">
                <v-spacer></v-spacer>

                <v-btn :color="colorFab" icon>
                  <v-icon large data-aos="fade-up" data-aos-easing="ease-in-sine">
                    mdi-heart-outline</v-icon
                  >
                </v-btn>
              </v-app-bar>
            </v-img>
            <v-card-title
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              v-if="$vuetify.breakpoint.lgAndUp"
            >
              Premium Vanilla Gelato
            </v-card-title>
            <v-card-text
              v-if="$vuetify.breakpoint.smAndDown"
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              class="black--text"
            >
              Premium Vanilla Gelato
            </v-card-text>
            <v-card-text class="text-left pl-8">
              <v-layout row wrap justify-start align-center pb-4>
                <v-flex xs10 lg3>
                  <div
                    class="font-weight-bold brown--text"
                    data-aos="fade-up"
                    data-aos-easing="ease-in-sine"
                  >
                    79 บาท
                  </div>
                </v-flex>
                <v-flex xs1 lg7> </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs5 lg4 pa-1>
          <v-card>
            <v-img
              @click="fn_showDeatailChocolate()"
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              max-height="360px"
              src="@/assets/img/Menu/Chocolate.jpg"
            >
              <v-app-bar flat color="rgba(0, 0, 0, 0)">
                <v-spacer></v-spacer>

                <v-btn color="white" icon>
                  <v-icon large data-aos="fade-up" data-aos-easing="ease-in-sine">
                    mdi-heart-outline</v-icon
                  >
                </v-btn>
              </v-app-bar>
            </v-img>
            <v-card-title
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              v-if="$vuetify.breakpoint.lgAndUp"
            >
              70% Dark Chocolate
            </v-card-title>
            <v-card-text
              v-if="$vuetify.breakpoint.smAndDown"
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              class="black--text"
            >
              70% Dark Chocolate
            </v-card-text>
            <v-card-text class="text-left pl-8">
              <v-layout row wrap justify-start align-center pb-4>
                <v-flex xs10 lg3>
                  <div
                    class="font-weight-bold brown--text"
                    data-aos="fade-up"
                    data-aos-easing="ease-in-sine"
                  >
                    79 บาท
                  </div>
                </v-flex>
                <v-flex xs1 lg7> </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs5 lg4 pa-1>
          <v-card>
            <v-img
              @click="fn_showDeatailMatcha()"
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              max-height="360px"
              src="@/assets/img/Menu/Matcha.jpg"
            >
              <v-app-bar flat color="rgba(0, 0, 0, 0)">
                <v-spacer></v-spacer>

                <v-btn color="white" icon>
                  <v-icon large data-aos="fade-up" data-aos-easing="ease-in-sine">
                    mdi-heart-outline</v-icon
                  >
                </v-btn>
              </v-app-bar>
            </v-img>
            <v-card-title
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              v-if="$vuetify.breakpoint.lgAndUp"
            >
              Matcha Gelato
            </v-card-title>
            <v-card-text
              v-if="$vuetify.breakpoint.smAndDown"
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              class="black--text"
            >
              Matcha Gelato
            </v-card-text>
            <v-card-text class="text-left pl-8">
              <v-layout row wrap justify-start align-center pb-4>
                <v-flex xs10 lg3>
                  <div
                    class="font-weight-bold brown--text"
                    data-aos="fade-up"
                    data-aos-easing="ease-in-sine"
                  >
                    79 บาท
                  </div>
                      <div         v-if="$vuetify.breakpoint.smAndDown">
                  <br>
                </div>
                </v-flex>
                <v-flex xs1 lg7> </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs5 lg4 pa-1>
          <v-card>
            <v-img
              @click="fn_showDeatailCookie()"
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              max-height="360px"
              src="@/assets/img/Menu/Cookie.jpg"
            >
              <v-app-bar flat color="rgba(0, 0, 0, 0)">
                <v-spacer></v-spacer>

                <v-btn color="white" icon data-aos="fade-up" data-aos-easing="ease-in-sine">
                  <v-icon large> mdi-heart-outline</v-icon>
                </v-btn>
              </v-app-bar>
            </v-img>
            <v-card-title
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              v-if="$vuetify.breakpoint.lgAndUp"
            >
              Cookie & Cream Gelato
            </v-card-title>
            <v-card-text
              v-if="$vuetify.breakpoint.smAndDown"
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              class="black--text"
            >
              Cookie & Cream Gelato
            </v-card-text>
            <v-card-text class="text-left pl-8">
              <v-layout row wrap justify-start align-center pb-4>
                <v-flex xs10 lg3>
                  <div
                    class="font-weight-bold brown--text"
                    data-aos="fade-up"
                    data-aos-easing="ease-in-sine"
                  >
                    89 บาท
                  </div>
                </v-flex>
                <v-flex xs1 lg7> </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs5 lg4 pa-1>
          <v-card>
            <v-img
              @click="fn_showDeatailToffee()"
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              max-height="360px"
              src="@/assets/img/Menu/Toffee.jpg"
            >
              <v-app-bar flat color="rgba(0, 0, 0, 0)">
                <v-spacer></v-spacer>

                <v-btn color="white" icon>
                  <v-icon large data-aos="fade-up" data-aos-easing="ease-in-sine">
                    mdi-heart-outline</v-icon
                  >
                </v-btn>
              </v-app-bar>
            </v-img>
            <v-card-title
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              v-if="$vuetify.breakpoint.lgAndUp"
            >
              Toffee Coffee Gelato
            </v-card-title>
            <v-card-text
              v-if="$vuetify.breakpoint.smAndDown"
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              class="black--text"
            >
              Toffee Coffee Gelato
            </v-card-text>
            <v-card-text class="text-left pl-8">
              <v-layout row wrap justify-start align-center pb-4>
                <v-flex xs10 lg3>
                  <div
                    class="font-weight-bold brown--text"
                    data-aos="fade-up"
                    data-aos-easing="ease-in-sine"
                  >
                    99 บาท
                  </div>
                </v-flex>
                <v-flex xs1 lg7> </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs5 lg4 pa-1>
          <v-card>
            <v-img
              @click="fn_showDeatailBlueberry()"
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              max-height="360px"
              src="@/assets/img/Menu/Blueberry.jpg"
            >
              <v-app-bar flat color="rgba(0, 0, 0, 0)">
                <v-spacer></v-spacer>

                <v-btn color="white" icon>
                  <v-icon large data-aos="fade-up" data-aos-easing="ease-in-sine">
                    mdi-heart-outline</v-icon
                  >
                </v-btn>
              </v-app-bar>
            </v-img>
            <v-card-title
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              v-if="$vuetify.breakpoint.lgAndUp"
            >
              Blueberry Yogurt Gelato
            </v-card-title>
            <v-card-text
              v-if="$vuetify.breakpoint.smAndDown"
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              class="black--text"
            >
              Blueberry Yogurt Gelato
            </v-card-text>
            <v-card-text class="text-left pl-8">
              <v-layout row wrap justify-start align-center pb-4>
                <v-flex xs10 lg3>
                  <div
                    class="font-weight-bold brown--text"
                    data-aos="fade-up"
                    data-aos-easing="ease-in-sine"
                  >
                    99 บาท
                  </div>
                </v-flex>
                <v-flex xs1 lg7> </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs5 lg4 pa-1>
          <v-card>
            <v-img
              @click="fn_showDeatailStrawberry()"
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              max-height="360px"
              src="@/assets/img/Menu/Strawberry.jpg"
            >
              <v-app-bar flat color="rgba(0, 0, 0, 0)">
                <v-spacer></v-spacer>

                <v-btn color="white" icon>
                  <v-icon large data-aos="fade-up" data-aos-easing="ease-in-sine">
                    mdi-heart-outline</v-icon
                  >
                </v-btn>
              </v-app-bar>
            </v-img>
            <v-card-title
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              v-if="$vuetify.breakpoint.lgAndUp"
            >
              Strawberry Cheesecake Crumble
            </v-card-title>
            <v-card-text
              v-if="$vuetify.breakpoint.smAndDown"
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              class="black--text"
            >
              Strawberry Cheesecake Crumble
            </v-card-text>
            <v-card-text class="text-left pl-8">
              <v-layout row wrap justify-start align-center pb-4>
                <v-flex xs10 lg3>
                  <div
                    class="font-weight-bold brown--text"
                    data-aos="fade-up"
                    data-aos-easing="ease-in-sine"
                  >
                    99 บาท
                  </div>
                </v-flex>
                <v-flex xs1 lg7> </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs5 lg4 pa-1>
          <v-card>
            <v-img
              @click="fn_showDeatailCrunchy()"
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              max-height="360px"
              src="@/assets/img/Menu/Crunchy.jpg"
            >
              <v-app-bar flat color="rgba(0, 0, 0, 0)">
                <v-spacer></v-spacer>

                <v-btn color="white" icon>
                  <v-icon large data-aos="fade-up" data-aos-easing="ease-in-sine">
                    mdi-heart-outline</v-icon
                  >
                </v-btn>
              </v-app-bar>
            </v-img>
            <v-card-title
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              v-if="$vuetify.breakpoint.lgAndUp"
            >
              Crunchy Caramellow
            </v-card-title>
            <v-card-text
              v-if="$vuetify.breakpoint.smAndDown"
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              class="black--text"
            >
              Crunchy Caramellow
            </v-card-text>
            <v-card-text class="text-left pl-8">
              <v-layout row wrap justify-start align-center pb-4>
                <v-flex xs10 lg3>
                  <div
                    class="font-weight-bold brown--text"
                    data-aos="fade-up"
                    data-aos-easing="ease-in-sine"
                  >
                    119 บาท
                  </div>
                    <div         v-if="$vuetify.breakpoint.smAndDown">
                  <br>
                </div>
                </v-flex>
                <v-flex xs1 lg7> </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs5 lg4 pa-1>
          <v-card>
            <v-img
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              @click="fn_showDeatailArtisan()"
              max-height="360px"
              src="@/assets/img/Menu/Artisan.jpg"
            >
              <v-app-bar flat color="rgba(0, 0, 0, 0)">
                <v-spacer></v-spacer>

                <v-btn color="white" icon>
                  <v-icon large data-aos="fade-up" data-aos-easing="ease-in-sine">
                    mdi-heart-outline</v-icon
                  >
                </v-btn>
              </v-app-bar>
            </v-img>
            <v-card-title
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              v-if="$vuetify.breakpoint.lgAndUp"
            >
              Artisan Strawberry Cheesecake
            </v-card-title>
            <v-card-text
              v-if="$vuetify.breakpoint.smAndDown"
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              class="black--text"
            >
              Artisan Strawberry Cheesecake
            </v-card-text>
            <v-card-text class="text-left pl-8">
              <v-layout row wrap justify-start align-center pb-4>
                <v-flex xs10 lg3>
                  <div
                    class="font-weight-bold brown--text"
                    data-aos="fade-up"
                    data-aos-easing="ease-in-sine"
                  >
                    99 บาท
                  </div>
                </v-flex>
                <v-flex xs1 lg7> </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs5 lg4 pa-1>
          <v-card>
            <v-img
              @click="fn_showDeatailPineapple()"
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              max-height="360px"
              src="@/assets/img/Menu/Pineapple.jpg"
            >
              <v-app-bar flat color="rgba(0, 0, 0, 0)">
                <v-spacer></v-spacer>

                <v-btn color="white" icon>
                  <v-icon large data-aos="fade-up" data-aos-easing="ease-in-sine">
                    mdi-heart-outline</v-icon
                  >
                </v-btn>
              </v-app-bar>
            </v-img>
            <v-card-title
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              v-if="$vuetify.breakpoint.lgAndUp"
            >
              Pineapple Passion Fruit Sorbetto
            </v-card-title>
            <v-card-text
              v-if="$vuetify.breakpoint.smAndDown"
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              class="black--text"
            >
              Pineapple Passion Fruit Sorbetto
            </v-card-text>
            <v-card-text class="text-left pl-8">
              <v-layout row wrap justify-start align-center pb-4>
                <v-flex xs10 lg3>
                  <div
                    class="font-weight-bold brown--text"
                    data-aos="fade-up"
                    data-aos-easing="ease-in-sine"
                  >
                    89 บาท
                  </div>
                </v-flex>
                <v-flex xs1 lg7> </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs5 lg4 pa-1>
          <v-card>
            <v-img
              @click="fn_showDeatailLemon()"
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              max-height="360px"
              src="@/assets/img/Menu/Lemon.jpg"
            >
              <v-app-bar flat color="rgba(0, 0, 0, 0)">
                <v-spacer></v-spacer>

                <v-btn color="white" icon>
                  <v-icon large data-aos="fade-up" data-aos-easing="ease-in-sine">
                    mdi-heart-outline</v-icon
                  >
                </v-btn>
              </v-app-bar>
            </v-img>
            <v-card-title
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              v-if="$vuetify.breakpoint.lgAndUp"
            >
              Honey Lemon Sorbetto
            </v-card-title>
            <v-card-text
              v-if="$vuetify.breakpoint.smAndDown"
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              class="black--text"
            >
              Honey Lemon Sorbetto
            </v-card-text>
            <v-card-text class="text-left pl-8">
              <v-layout row wrap justify-start align-center pb-4>
                <v-flex xs10 lg3>
                  <div
                    class="font-weight-bold brown--text"
                    data-aos="fade-up"
                    data-aos-easing="ease-in-sine"
                  >
                    99 บาท
                  </div>
                </v-flex>
                <v-flex xs1 lg7> </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs5 lg4 pa-1>
          <v-card>
            <v-img
              @click="fn_showDeatailYuzu()"
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              max-height="360px"
              src="@/assets/img/Menu/Yuzu.jpg"
            >
              <v-app-bar flat color="rgba(0, 0, 0, 0)">
                <v-spacer></v-spacer>

                <v-btn color="white" icon>
                  <v-icon large data-aos="fade-up" data-aos-easing="ease-in-sine">
                    mdi-heart-outline</v-icon
                  >
                </v-btn>
              </v-app-bar>
            </v-img>
            <v-card-title
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              v-if="$vuetify.breakpoint.lgAndUp"
            >
              Yuzu x MAndarin Sorbetto
            </v-card-title>
            <v-card-text
              v-if="$vuetify.breakpoint.smAndDown"
              data-aos="fade-up"
              data-aos-easing="ease-in-sine"
              class="black--text"
            >
              Yuzu x MAndarin Sorbetto
            </v-card-text>
            <v-card-text class="text-left pl-8">
              <v-layout row wrap justify-start align-center pb-4>
                <v-flex xs10 lg3>
                  <div
                    class="font-weight-bold brown--text"
                    data-aos="fade-up"
                    data-aos-easing="ease-in-sine"
                  >
                    139 บาท
                  </div>
                </v-flex>
                <v-flex xs1 lg7> </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
      <Vanilla :show="modalVanilla" @close="modalVanilla = false"></Vanilla>
      <Chocolate :show="modalChocolate" @close="modalChocolate = false"></Chocolate>
      <Matcha :show="modalMatcha" @close="modalMatcha = false"></Matcha>
      <Cookie :show="modalCookie" @close="modalCookie = false"></Cookie>
      <Toffee :show="modalToffee" @close="modalToffee = false"></Toffee>
      <Blueberry :show="modalBlueberry" @close="modalBlueberry = false"></Blueberry>
      <Strawberry :show="modalStrawberry" @close="modalStrawberry = false"></Strawberry>
      <Crunchy :show="modalCrunchy" @close="modalCrunchy = false"></Crunchy>
      <Artisan :show="modalArtisan" @close="modalArtisan = false"></Artisan>
      <Pineapple :show="modalPineapple" @close="modalPineapple = false"></Pineapple>
      <Lemon :show="modalLemon" @close="modalLemon = false"></Lemon>
      <Yuzu :show="modalYuzu" @close="modalYuzu = false"></Yuzu>
    </v-container>
    <Footer />
  </v-app>
</template>
<script>
import Toolbar from '@/components/Toolbar.vue'
import Footer from '@/components/Footer.vue'
import Vanilla from '@/views/dialogs/Vanilla.vue'
import Chocolate from '@/views/dialogs/Chocolate.vue'
import Matcha from '@/views/dialogs/Matcha.vue'
import Cookie from '@/views/dialogs/Cookie.vue'
import Toffee from '@/views/dialogs/Toffee.vue'
import Blueberry from '@/views/dialogs/Blueberry.vue'
import Strawberry from '@/views/dialogs/Strawberry.vue'
import Crunchy from '@/views/dialogs/Crunchy.vue'
import Artisan from '@/views/dialogs/Artisan.vue'
import Pineapple from '@/views/dialogs/Pineapple.vue'
import Lemon from '@/views/dialogs/Lemon.vue'
import Yuzu from '@/views/dialogs/Yuzu.vue'

export default {
  components: {
    Toolbar,
    Footer,
    Vanilla,
    Chocolate,
    Matcha,
    Cookie,
    Toffee,
    Blueberry,
    Strawberry,
    Crunchy,
    Artisan,
    Pineapple,
    Lemon,
    Yuzu
  },
  data: () => ({
    items: ['foo', 'bar', 'fizz', 'buzz'],
    value: [],
    modalVanilla: false,
    modalChocolate: false,
    modalMatcha: false,
    modalCookie: false,
    modalToffee: false,
    modalBlueberry: false,
    modalStrawberry: false,
    modalCrunchy: false,
    modalArtisan: false,
    modalPineapple: false,
    modalLemon: false,
    modalYuzu: false,
    colorFab: 'white'
  }),
  methods: {
    fn_showDeatailVanilla () {
      this.modalVanilla = true
    },
    fn_showDeatailChocolate () {
      this.modalChocolate = true
    },
    fn_showDeatailMatcha () {
      this.modalMatcha = true
    },
    fn_showDeatailCookie () {
      this.modalCookie = true
    },
    fn_showDeatailToffee () {
      this.modalToffee = true
    },
    fn_showDeatailBlueberry () {
      this.modalBlueberry = true
    },
    fn_showDeatailStrawberry () {
      this.modalStrawberry = true
    },
    fn_showDeatailCrunchy () {
      this.modalCrunchy = true
    },
    fn_showDeatailArtisan () {
      this.modalArtisan = true
    },
    fn_showDeatailPineapple () {
      this.modalPineapple = true
    },
    fn_showDeatailLemon () {
      this.modalLemon = true
    },
    fn_showDeatailYuzu () {
      this.modalYuzu = true
    }
  }
}
</script>
<style scoped>
.font-color {
  font-size: 60px;
  color: #9c7656;
}
.background {
  background-color: #edede7;
}
</style>

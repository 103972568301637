<template>
  <div class="text-center">
    <v-dialog scrollable v-model="shower" max-width="650" persistent>
      <v-card class="elevation-1">
        <v-card-actions class="pa-5">
          <v-btn dark color="#CBB5A1" fab small @click="fn_cancle()">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-text style="text-align: center">
          <v-img
             max-height="480px"
            data-aos="fade-up"
            data-aos-easing="ease-in-sine"
            src="@/assets/img/Menu/Yuzu.jpg"
          ></v-img>
          <v-card-title primary-title> Yuzu x MAndarin Sorbetto </v-card-title>
          <div class="text-left">
            Luxury taste : รสพรีเมียมของส้มยุสุที่นำเข้าจากเกาหลี ( 유자 )
            ด้วยรสชาติที่เปรี้ยวอ่อนๆละมุนๆ
            มีกลิ่นหอมสดชื่นที่ใครๆก็ต่างหลงรักเอกลักษณ์เฉพาะที่หาจากส้มที่ไหนไม่ได้ Local orange :
            ส้มแมนดาริน รสชาติหวานฉ่ำ ตัดรสเปรี้ยวของส้มยุสุจนได้รสเปรี้ยวอมหวานชัดเจน
          </div>
          <br />
        </v-card-text>
        <v-card-text style="text-align: right">
          <v-chip class="ma-2 brown--text" color="#CBB5A1"> 139 บาท </v-chip>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: ['show'],
  components: {},
  data: function () {
    return {}
  },
  computed: {
    shower: {
      get () {
        return this.show
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      }
    }
  },
  methods: {
    fn_cancle () {
      this.$emit('close')
    }
  }
}
</script>

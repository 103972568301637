<template>
  <div class="text-center">
    <v-dialog scrollable v-model="shower" max-width="650" persistent>
      <v-card class="elevation-1">
        <v-card-actions class="pa-5">
          <v-btn dark color="#CBB5A1" fab small @click="fn_cancle()">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-text style="text-align: center">
          <v-img
             max-height="480px"
            data-aos="fade-up"
            data-aos-easing="ease-in-sine"
            src="@/assets/img/Menu/Pineapple.jpg"
          ></v-img>
          <v-card-title primary-title> Pineapple Passion Fruit Sorbetto </v-card-title>
          <div class="text-left">
            ทางร้านได้นำผลไม้สดจากเกษตรกร อย่างเสาวรส มารังสรรค์เป็นซอร์เบทโต้ รสชาติหวานอมเปรี้ยว
            ความพิเศษอยู่ตรงที่ ’ซอสเสาวรส’ ที่เราใช้เทคนิค fruit compote ในการทำ!
            โดยการนำเสาวรสไปเคี่ยวกับน้ำตาลด้วยไฟอ่อนๆ
            ค่อยๆให้ความหวานของน้ำตาลไปผสมกับความเปรี้ยวของเสาวรสอย่างช้าๆ
            โดยที่เสาวรสยังคงรูปและเนื้อสัมผัสอยู่
          </div>
          <br />
        </v-card-text>
        <v-card-text style="text-align: right">
          <v-chip class="ma-2 brown--text" color="#CBB5A1"> 89 บาท </v-chip>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: ['show'],
  components: {},
  data: function () {
    return {}
  },
  computed: {
    shower: {
      get () {
        return this.show
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      }
    }
  },
  methods: {
    fn_cancle () {
      this.$emit('close')
    }
  }
}
</script>

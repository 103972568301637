<template>
  <div class="text-center">
    <v-dialog scrollable v-model="shower" max-width="650" persistent>
      <v-card class="elevation-1">
        <v-card-actions class="pa-5">
          <v-btn dark color="#CBB5A1" fab small @click="fn_cancle()">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-text style="text-align: center">
          <v-img
             max-height="480px"
            data-aos="fade-up"
            data-aos-easing="ease-in-sine"
            src="@/assets/img/Menu/Blueberry.jpg"
          ></v-img>
          <v-card-title primary-title> Blueberry Yogurt Gelato </v-card-title>
          <div class="text-left">
            โยเกิร์ตของฝรั่งเศส เป็นรสที่เราประทับใจ เนื่องจากเป็นโยเกิร์ตที่ไม่ออกรสนม
            หรือรสเปรี้ยวมากเกินพอดี จนรู้สึกว่าเป็นนมเปรี้ยวมากกว่าจะเป็นโยเกิร์ต
            ซึ่งสามารถจูนรสได้พอดีกับ’ซอสบลูเบอร์รี่’เข้มข้น ที่เรานำมา swirl
            เป็นริ้วๆได้อย่างสวยงามไม่แพ้รสชาติ
          </div>
          <br />
        </v-card-text>
        <v-card-text style="text-align: right">
          <v-chip class="ma-2 brown--text" color="#CBB5A1"> 99 บาท </v-chip>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: ['show'],
  components: {},
  data: function () {
    return {}
  },
  computed: {
    shower: {
      get () {
        return this.show
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      }
    }
  },
  methods: {
    fn_cancle () {
      this.$emit('close')
    }
  }
}
</script>

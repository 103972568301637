<template>
  <div class="text-center">
    <v-dialog scrollable v-model="shower" max-width="650" persistent>
      <v-card class="elevation-1">
                  <v-card-actions class="pa-5">
          <v-btn dark color="#CBB5A1" fab small @click="fn_cancle()"> <v-icon>mdi-chevron-left</v-icon> </v-btn>
        </v-card-actions>
        <v-card-text style="text-align: center;">
          <v-img
             max-height="480px"
            data-aos="fade-up"
            data-aos-easing="ease-in-sine"
            src="@/assets/img/Menu/Vanilla.jpg"
          ></v-img>
          <v-card-title primary-title> Premium Vanilla Gelato </v-card-title>
          <div class="text-left">
            รสพรีเมียมวานิลลา เราใช้ฝักวานิลลาแท้จากอูกันด้า
            ซึ่งคุณจะเห็นเป็นจุดดำๆผสมอยู่ในเนื้อไอศกรีมสีขาวนวลจากนมสด
            ที่ไม่มีไข่แดงเป็นส่วนประกอบเลย
            จึงทำให้ได้รสหวานหอมละมุนของนมสดและกลิ่นหอมของฝักวานิลลาแบบเต็มๆ
          </div>
          <br />
        </v-card-text>
        <v-card-text style="text-align: right;">
          <v-chip  class="ma-2 brown--text " color="#CBB5A1"> 79 บาท </v-chip>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: ['show', 'data', 'template'],
  components: {},
  data: function () {
    return {
    }
  },
  computed: {
    shower: {
      get () {
        return this.show
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      }
    }
  },
  methods: {
    fn_cancle () {
      this.$emit('close')
    }
  }
}
</script>
